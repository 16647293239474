import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from './CartContext'; 
import http from 'utils/http-common';

const CartPreview = () => {
    const { userCart, removeCartItem, clearCart } = useCart();
    const navigate = useNavigate(); 
    const [successMessage, setSuccessMessage] = useState('');

    const handleEditClick = (index) => {
        navigate(`/blindsquote/${index}`);
    };

    const submitForQuote = async(e) => {
        e.preventDefault();
        try {
            const response = await http.post('/blindsquote/emailquote', userCart);
            
            if (response.status === 200) {
                // Request was successful
                clearCart();

                setSuccessMessage(response.data.message);
            } 
        } catch (error) {
            console.error('Error submitting Quote data:', error);
            navigate('/error');
        }
    };


    return <>
        <div className='bg-[var(--primary-dark)] py-4 md:py-4 mt-10'>
            <div className='container mx-auto text-white'>
                <h1 className='text-base text-center md:text-2xl font-bold'>Shopping Cart Preview</h1>
            </div>
        </div>
        {successMessage && (
            <p className='text-green-500 m-2'>{successMessage}.</p>
        )}

        <div className="container mx-auto mt-5">
            {userCart.cartItems.length === 0 ? (
                <p>Your shopping cart is empty.</p>
            ) : (
                <>
                    {userCart.cartItems.map((item, index) => (
                        <div key={index} className="mb-4 p-4 bg-gray-100 rounded-lg shadow-md">
                            <div className="flex items-center">
                                <div className="w-1/4">
                                <strong className="font-bold">Room# </strong>{item.roomName}
                                </div>
                                <div className="w-2/4 ml-4">
                                    <p className="text-gray-600"><strong className="font-bold pr-1">Fabric Code:</strong>{item.fabricCode}</p>
                                    <p className="text-gray-600"><strong className="font-bold pr-1">Width:</strong>{item.width}{' inches'}</p>
                                    <p className="text-gray-600"><strong className="font-bold pr-1">Height:</strong>{item.height}{' inches'}</p>
                                    <div className="text-gray-600 inline-block">
                                        <span className="inline pr-1 font-bold">Control Type:</span>
                                        <span className="inline pr-1">{item.controlType}</span>
                                        {item.controlType === 'Manual' && (
                                            <>
                                                <span className="inline pr-1">{'('}{item.chainControlStyle}{','}</span>
                                                <span className="inline pr-1">{item.chainControlSide}{','}</span>
                                                <span className="inline pr-1">{item.chainLength}{' inches)'}</span>
                                            </>
                                        )}
                                        {item.controlType === 'Motorized' && (
                                            <>
                                                <span className="inline pr-1">{'('}{item.motorPosition}{','}</span>
                                                <span className="inline pr-1">{item.motorizedRemote}{','}</span>
                                                <span className="inline pr-1">{item.smartHub}{')'}</span>
                                            </>
                                        )}
                                    </div>
                                    <div className="text-gray-600">
                                        <span className="inline pr-1 font-bold">Top Position:</span>
                                        <span className="inline pr-1">{item.topOption}</span>
                                        {item.topOption === 'Cassette' && (
                                            <>
                                                <span className="inline pr-1">{'('}{item.cassetteType}{','}</span>
                                                <span className="inline pr-1">{item.cassetteSize}{','}</span>
                                                <span className="inline pr-1">{item.fabricInsert}{')'}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="w-1/8 ml-4">
                                    <button type="submit"
                                    onClick={() => handleEditClick(index)}
                                    className="bg-blue-500 text-white hover:text-green-300 mr-2"
                                    >
                                    Edit
                                    </button>
                                    <button type="submit"
                                    onClick={() => removeCartItem(index)}
                                    className="bg-blue-500 text-white hover:text-customRed"
                                    >
                                    Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}

            <div className="mt-4 flex justify-between items-center">
                <Link to="/" className="text-indigo-600 hover:text-indigo-900">
                    Continue Shopping
                </Link>
                <Link to="/blindsquote" className="text-indigo-600 hover:text-indigo-900">
                    Add more blinds
                </Link>
                <button type="submit" onClick={submitForQuote} className="text-blue-500 hover:text-blue-700 focus:outline-none">
                Submit for Quote
                </button>
            </div>

        </div>
    </>
};

export default CartPreview;
