import React from 'react'

const ErrorPage = () => {
  return <>
        <div className='text-center pt-10 md:pt-20 mt-8'>
            <h1 className='text-2xl md:text-4xl text-red-500'>Oops! Something went wrong.</h1>
            <p className='text-gray-600'>We apologize for the inconvenience. Please try again later.</p>
        </div>
    </>
}

export default ErrorPage
