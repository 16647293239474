import React from 'react';
import { useCart } from 'pages/blinds-quote/CartContext';

const fields = [
  { label: 'First Name', name: 'firstName', type: 'text', required: true },
  { label: 'Middle Name', name: 'middleName', type: 'text', required: false },
  { label: 'Last Name', name: 'lastName', type: 'text', required: true },
  { label: 'Phone', name: 'phone', type: 'text', required: true },
  { label: 'Email', name: 'email', type: 'email', required: false },
  { label: 'Address', name: 'address', type: 'text', required: true },
];

const QuoteUserForm = ({ userData, handleUserInputChange }) => {
  const { userCart } = useCart();

  return (
    <div className="mt-4 mb-4">
      <div className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        {fields.slice(0, 3).map((field, index) => (
          <div className="flex items-center mb-2 md:mb-0 md:mr-4" key={index}>
            <label className="text-right font-bold pr-2 w-28">{field.label}:</label>
            {userCart.cartItems.length > 0 ? (
              <p className="mt-2">{userData[field.name]}</p>
            ) : (
              <input
                type={field.type}
                id={field.name}
                name={field.name}
                value={userData[field.name]}
                onChange={handleUserInputChange}
                className="border border-gray-300 rounded-md p-2 w-full"
                maxLength="80"
                required={field.required}
              />
            )}
          </div>
        ))}
      </div>

      <div className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        {fields.slice(3).map((field, index) => (
          <div className="flex items-center mb-2 md:mb-0 md:mr-4" key={index}>
            <label className="text-right font-bold pr-2 w-28">{field.label}:</label>
            {userCart.cartItems.length > 0 ? (
              <p className="mt-2">{userData[field.name]}</p>
            ) : (
              <input
                type={field.type}
                id={field.name}
                name={field.name}
                value={userData[field.name]}
                onChange={handleUserInputChange}
                className="border border-gray-300 rounded-md p-2 w-full"
                maxLength="80"
                required={field.required}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuoteUserForm;
