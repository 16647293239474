import React from 'react'
import Rollers from './shades/Rollers'
import ZebraShades from './shades/ZebraShades'
import CellularShades from './shades/CellularShades'
import RomanShades from './shades/RomanShades'
import Curtains from './shades/Curtains'

const Catalogs = () => {
  return <>
    <Rollers />
    <ZebraShades />
    <CellularShades />
    <RomanShades />
    <Curtains />
  </>
}

export default Catalogs
