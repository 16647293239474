import React from 'react'
import FormRow from 'components/FormRow'

const CassetteOptions = ({ formData, handleInputChange }) => {
  return <>
        <FormRow label="Cassette Type" colspan={1}>
            <select
                id="cassetteType"
                name="cassetteType"
                value={formData.cassetteType}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 md:text-base"
                required
            >
                <option value="">Select</option>
                <option value="Square">Square</option>
                <option value="Round">Round</option>
            </select>
        </FormRow>

        <FormRow label="Cassette Size" colspan={1}>
            <select
                id="cassetteSize"
                name="cassetteSize"
                value={formData.cassetteSize}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 md:text-base"
                required
            >
                <option value="">Select</option>
                <option value="Large">Large</option>
                <option value="Small">Small</option>
            </select>
        </FormRow>

        <FormRow label="Fabric Insert" colspan={1}>
            <select
                id="fabricInsert"
                name="fabricInsert"
                value={formData.fabricInsert}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 md:text-base"
                required
            >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </FormRow>
  </>
}

export default CassetteOptions
