import React from 'react'
import Hero from 'components/Hero'
import ShadeTypes from 'pages/catalogs/ShadeTypes'
import Catalogs from 'pages/catalogs/Catalogs'
import Contact from 'components/Contact'
import ImageSlider from 'pages/misc/ImageSlider'

const Home = () => {
  return <>
    <Hero />
    <ShadeTypes />
    <Catalogs />
    <ImageSlider />
    <Contact />
  </>
}

export default Home
