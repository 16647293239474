import React from 'react';
import ImageCarousel from 'components/ImageCarousel';

const ImageSlider = () => {
    const images = [
        {
            imgSrc: require('images/customer-images/ci-1.jpg'),
        },
        {
            imgSrc: require('images/customer-images/ci-2.jpg'),
        },
        {
            imgSrc: require('images/customer-images/ci-3.jpg'),
        },
        {
            imgSrc: require('images/customer-images/ci-4.jpg'),
        },
        {
            imgSrc: require('images/customer-images/ci-5.jpg'),
        },
        {
            imgSrc: require('images/customer-images/ci-6.jpg'),
        },
        {
            imgSrc: require('images/customer-images/ci-7.jpg'),
        },
    ];

  return <>
    <ImageCarousel images={images} headerText='Customer Images' shadesId='customerimages' />
  </>
};

export default ImageSlider;
