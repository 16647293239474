import React from 'react';
import rollerShades from '../../images/roller/rollershades.jpg'
import zebraShades from '../../images/zebra/zebrashades.jpg'
import cellular from '../../images/cellular/cellularshades.jpg'
import roman from '../../images/roman/romanshades.jpg'
import curtains from '../../images/curtains/curtainsshades.jpg'

const ShadeTypes = () => {
  return (
    <div className='max-w-[1140px] mx-auto w-full md:flex mt-0 md:mt-[-160px]'>
      <div className='relative p-4'>
        <a href="#roller">
          <img
            className='w-full h-full object-cover relative border-4 border-white shadow-lg'
            src={rollerShades}
            alt='/'
          />
          <h1 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-900 text-2xl md:text-3xl font-bold'>Roller Shades</h1>
        </a>
      </div>
      <div className='relative p-4'>
        <a href="#zebra">
          <img
            className='w-full h-full object-cover relative border-4 border-white shadow-lg'
            src={zebraShades}
            alt='/'
          />
          <h3 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-900 text-2xl md:text-3xl font-bold'>Zebra Shades</h3>
        </a>
      </div>
      <div className='relative p-4'>
        <a href="#cellular">
          <img
            className='w-full h-full object-cover relative border-4 border-white shadow-lg'
            src={cellular}
            alt='/'
          />
          <h3 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-900 text-2xl md:text-3xl font-bold'>Cellular Shades</h3>
        </a>
      </div>
      <div className='relative p-4'>
        <a href="#roman">
          <img
            className='w-full h-full object-cover relative border-4 border-white shadow-lg'
            src={roman}
            alt='/'
          />
          <h3 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-900 text-2xl md:text-3xl font-bold'>Roman Shades</h3>
        </a>
      </div>
      <div className='relative p-4'>
        <a href="#curtains">
          <img
            className='w-full h-full object-cover relative border-4 border-white shadow-lg'
            src={curtains}
            alt='/'
          />
          <h3 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-900 text-2xl md:text-3xl font-bold'>Curtains</h3>
        </a>
      </div>
    </div>
  );
};

export default ShadeTypes;
