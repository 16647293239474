import React, { useState, useRef, useEffect } from 'react';
import {
  FaFacebookF,
  FaTwitter,
  FaGooglePlusG,
  FaInstagram,
  FaBars,
} from 'react-icons/fa';
import measureTemplate from 'docs/XpertsBlinds-Quote-Template.xlsx';
import { HashLink as Link } from 'react-router-hash-link';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const menuRef = useRef(null);

  const handleNav = (e) => {
    e.stopPropagation();
    setNav(!nav);
    setActiveSubmenu(null);
  };

  const handleSubMenu = (submenuName) => () => {
    setActiveSubmenu(activeSubmenu === submenuName ? null : submenuName);
  };

  const handleContactClick = () => {
    setNav(false);
    setActiveSubmenu(null);
  };

  // Handle click outside the menu to close it
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setNav(false);
        setActiveSubmenu(null);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className='w-full min-h-[50px] flex justify-between items-center absolute z-10 text-white bg-gray-700'>
      <ul className='hidden sm:flex px-4'>
        <li>
        <Link to="/">Home</Link>
        </li>
        <li
          onMouseEnter={handleSubMenu('products')}
          onMouseLeave={handleSubMenu('products')}
          className='whitespace-nowrap relative'
        >
          <a href='#roller'>Shades/Blinds</a>
          {activeSubmenu === 'products' && (
            <div className='absolute top-[40px] left-0 mt-2 bg-gray-700 text-white py-2 px-4 grid grid-cols-auto gap-2'>
              <Link to="/#roller">Roller</Link>
              <Link to="/#zebra">Zebra</Link>
              <Link to="/#cellular">HoneyComb/Celluar</Link>
              <Link to="/#roman">Roman</Link>
              <Link to="/#curtains">Curtains</Link>
            </div>
          )}
        </li>
        <li>
          <a href='#about'>About Us</a>
        </li>
        <li
          onClick={handleContactClick}
        >
          <Link to="/#contact">Contact</Link>
        </li>
        <li
          onMouseEnter={handleSubMenu('help')}
          onMouseLeave={handleSubMenu('help')}
          className='whitespace-nowrap relative'
        >
          <a href='#help'>Help</a>
          {activeSubmenu === 'help' && (
            <div className='absolute top-[40px] left-0 mt-2 bg-gray-700 text-white py-2 px-4 grid grid-cols-auto gap-2'>
               <Link to="/measuringtips">Measuring Tips</Link>
              <a href={measureTemplate} onClick={handleContactClick}>Blinds Measurement Template</a>
            </div>
          )}
        </li>
      </ul>
      <div className='flex justify-between'>
        <FaFacebookF className='mx-4' />
        <FaTwitter className='mx-4' />
        <FaGooglePlusG className='mx-4' />
        <FaInstagram className='mx-4' />
      </div>
      {/* Hamburger Icon */}
      <div onClick={handleNav} className='sm:hidden z-10 cursor-pointer'>
        <FaBars size={20} className='mr-4' />
      </div>
      {/* Mobile Menu */}
      <div
        ref={menuRef}
        className={
          nav
            ? 'md:hidden ease-in duration-300 absolute text-gray-300 left-0 top-0 w-full bg-gray-700 px-4 py-7 flex flex-col'
            : 'absolute top-0 h-0 left-[-100%] ease-in duration-500'
        }
      >
        <ul className='h-auto w-full text-center py-8'>
          <li className='text-2xl py-4'>
            <a href='/'>Home</a>
          </li>
          <li
            className='text-2xl py-4'
            onClick={handleSubMenu('products')}
          >
            Shades/Blinds
            {activeSubmenu === 'products' && (
              <div className='relative'>
                <div className='md:absolute top-full left-0 mt-2 bg-gray-700 text-white py-2 px-4 grid grid-cols-auto gap-2'>
                  <Link to="/#roller">Roller</Link>
                  <Link to="/#zebra">Zebra</Link>
                  <Link to="/#cellular">HoneyComb/Celluar</Link>
                  <Link to="/#roman">Roman</Link>
                  <Link to="/#curtains">Curtains</Link>
                </div>
              </div>
            )}
          </li>
          <li className='text-2xl py-4'>
            <a href='#about'>About Us</a>
          </li>
          <li
            className='text-2xl py-4'
            onClick={handleSubMenu('help')}
          >
            Help
            {activeSubmenu === 'help' && (
              <div className='relative'>
                <div className='md:absolute top-full left-0 mt-2 bg-gray-700 text-white py-2 px-4'>
                <Link to="/measuringtips">Measuring Tips</Link>
                  <a href={measureTemplate} onClick={handleContactClick}>Blinds Measurement Template</a>
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
