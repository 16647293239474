import React, { useState, useCallback, useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';

const CatalogSlider = ({ images, headerText, shadesId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, images.length]);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [nextSlide]);

  return <>
    <div id={shadesId} className='mb-4 text-center text-gray-700 font-semibold text-lg pt-14'>
        {headerText}
      </div>
    <div className='max-w-[600px] h-[300px] w-full m-auto  relative group'>
      <div className='relative w-full h-full rounded-2xl bg-center bg-cover duration-500'>
        {/* Header */}
        <div className='absolute rounded-2xl top-0 left-0 w-full bg-black/70 text-white py-2 px-4 text-center'>
          {images[currentIndex].headerText}
        </div>
        <a
          href={images[currentIndex].pdfUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* Image */}
          <div
            className='w-full h-full rounded-2xl bg-center bg-cover duration-500'
            style={{ backgroundImage: `url(${images[currentIndex].imgSrc})` }}
          />
        </a>
      </div>
      {/* Left Arrow */}
      <div className='absolute top-1/2 -translate-y-1/2 left-5 transform -translate-y-1/2 text-2xl rounded-full p-2 group-hover:bg-black/60 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className='absolute top-1/2 -translate-y-1/2 right-5 transform -translate-y-1/2 text-2xl rounded-full p-2 group-hover:bg-black/60 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>

      <div className='flex top-4 justify-center py-2'>
        {images.map((slide, slideIndex) => (
          <div
            className='text-2xl cursor-pointer'
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  </>
};

export default CatalogSlider;
