import React from 'react';

const FormRow = ({ label, children, colspan }) => {
  return (
    <div className={`grid grid-cols-1 md:grid-cols-4 gap-x-2 mb-4`}>
      <div className={`text-center md:text-right font-bold px-4 py-2 md:col-span-${colspan}`}>{label}:</div>
      <div className={`text-center md:text-left pl-5 pr-5 md:pl-2 md:col-span-${4 - colspan}`}>{children}</div>
    </div>
  );
};

export default FormRow;
