import React from 'react'
import CatalogSlider from '../../../components/CatalogSlider';

const CellularShades = () => {
    const rollerImages = [
        {
          imgSrc: require('images/cellular/cellularcatalog.jpg'),
          headerText: 'Cellular/Honeycomb Shades',
          pdfUrl: require('catalogs/Cellular/cellular-catalog.pdf') 
        },
    ];
    

    return <>
        <CatalogSlider images={rollerImages} headerText="Cellular Shade Catalogs" shadesId='cellular' />;
    </>
}

export default CellularShades