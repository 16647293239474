import React from 'react'
import CatalogSlider from '../../../components/CatalogSlider';

const RomanShades = () => {
    const rollerImages = [
        {
          imgSrc: require('images/roman/romancatalog.jpg'),
          headerText: 'Roman Shades',
          pdfUrl: require('catalogs/Roman/roman-catalog.pdf')
        },
    ];
    

    return <>
        <CatalogSlider images={rollerImages} headerText="Roman Shade Catalogs" shadesId='roman' />;
    </>
}

export default RomanShades