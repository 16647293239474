import React from 'react'
import FormRow from '../../components/FormRow'

const ManualControlOptions = ({ formData, handleInputChange }) => {
  return <>
    <FormRow label="Chain Style" colspan={1}>
      <select
        id="chainControlStyle"
        name="chainControlStyle"
        value={formData.chainControlStyle}
        onChange={handleInputChange}
        className="border border-gray-300 rounded-md p-2 md:text-base"
        required
      >
        <option value="">Select</option>
        <option value="Plastic">Plastic</option>
        <option value="Steel">Steel</option>
      </select>
    </FormRow>

    <FormRow label="Chain Side" colspan={1}>
      <select
        id="chainControlSide"
        name="chainControlSide"
        value={formData.chainControlSide}
        onChange={handleInputChange}
        className="border border-gray-300 rounded-md p-2 md:text-base"
        required
      >
        <option value="">Select</option>
        <option value="Right">Right</option>
        <option value="Left">Left</option>
      </select>
    </FormRow>

    <FormRow label="Chain Length" colspan={1}>
      <input
        type="text"
        id="chainLength"
        name="chainLength"
        value={formData.chainLength}
        onChange={handleInputChange}
        className="border border-gray-300 rounded-md p-2"
        maxLength="80"
        required
      />
    </FormRow>
  </>
}

export default ManualControlOptions
