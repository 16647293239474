import React from 'react';
import CartPreview from './CartPreview';

const CartPreviewPageWrapper = () => {
  return <>
        <CartPreview />
    </>
}

export default CartPreviewPageWrapper

