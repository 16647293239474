import React from 'react';
import measureScale from 'images/help/measurescale.png';
import window from 'images/help/XB-MeasuringWindowPic.png';
import insideMount from 'images/help/InsideMount.jpeg';
import outsideMount from 'images/help/OutsideMount.jpeg';
import measureTemplate from 'docs/XpertsBlinds-Quote-Template.xlsx';
import insideMountBrackets from 'images/help/InsideMountBrackets.jpg'


const MeasuringTips = () => {
  return <>
      <div className='bg-[var(--primary-dark)] py-4 md:py-4 mt-10'>
        <div className='container mx-auto text-white'>
          <h1 className='text-base text-center md:text-3xl font-bold'>Measuring Tips for Blinds</h1>
        </div>
      </div>
      <div className='container mx-auto py-6'>
        <div className='max-w-6xl mx-auto'>
          <p className='text-sm md:text-xl text-teal-600 font-semibold text-center'>
            Proper measuring is crucial to ensure your blinds fit perfectly. Follow these tips to get accurate measurements:
          </p>
        </div>
        <div className='max-w-6xl mx-auto mt-6 md:flex'>
          <div className='w-full md:w-1/2 pr-6'>
            <p className='text-base md:text-lg text-left'>
              Use a metal tape measure for precision.
              Measure width and height for each window.
              Measure at least three times to confirm accuracy.
              Round measurements to the nearest 1/8 inch.
            </p>
          </div>
          <div className='w-full md:w-1/2'>
            <img src={measureScale} alt='' className='w-[40%] h-auto' />
          </div>
        </div>
        <div className='text-center mt-10'>
          <h2 className='text-xl text-teal-600 font-semibold'>Instructions for Inside & Outside Mounts</h2>
          <img src={window} alt='' className='w-[60%] md:w-[40%] h-auto mx-auto' />
        </div>
        <div className='max-w-6xl mx-auto mt-6 md:flex'>
          <div className='w-full md:w-1/2 pr-6'>
            <h2 className='text-base text-teal-600 font-semibold'>Inside Mount</h2>
            <ul className='text-base md:text-lg text-left'>
                <li><strong>Width:</strong> Measure the width of the window opening at the top, middle, and bottom. Use the narrowest width measurement.</li>
                <li><strong>Height:</strong> Measure the height from the top inside of the window opening to the sill at the left, center, and right. Use the longest height measurement.</li>
                <li><strong>Depth:</strong> The minimum required depth is 2.8 inches (70mm).</li>
            </ul>
          </div>
          <div className='w-full md:w-1/2'>
            <img src={insideMount} alt='' className='w-[40%] h-auto' />
          </div>
        </div>
        <div className='max-w-6xl mx-auto mt-6 md:flex'>
          <div className='w-full md:w-1/2 pr-6'>
            <h2 className='text-base text-teal-600 font-semibold'>Outside Mount</h2>
            <ul className='text-base md:text-lg text-left'>
                <li><strong>Width:</strong> Measure the desired width of the area you want the blind to cover.</li>
                <li><strong>Height:</strong> Measure the desired height from the top of where you'll install the blind to the bottom.</li>
            </ul>
          </div>
          <div className='w-full md:w-1/2'>
            <img src={outsideMount} alt='' className='w-[40%] h-auto' />
          </div>
        </div>
        <div className='max-w-6xl mx-auto mt-6 md:flex'>
          <div className='w-full md:w-1/2 pr-6'>
            <h2 className='text-base text-teal-600 font-semibold'>Installation</h2>
            <ul className='text-base md:text-lg text-left'>
                <li>Illustration shows inside mount. Installing your blinds is a breeze with our user-friendly design. 
                  You can easily do it yourself, no professional help required. 
                  We've made it simple, so you'll have your blinds up and looking fantastic in no time. Picture shows inside mount</li>
            </ul>
          </div>
          <div className='w-full md:w-1/2'>
            <img src={insideMountBrackets} alt='' className='w-[40%] h-auto' />
          </div>
        </div>
      </div>

      <div className='py-2 md:py-4'>
        <div className='container mx-auto'>
          <strong>Link to download measurements template: </strong>
          <a href={measureTemplate} className='text-blue-500 hover:underline'>Blinds Measurement Template</a>
        </div>
      </div>
  </>
};

export default MeasuringTips;
