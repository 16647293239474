import React from 'react'
import FormRow from '../../components/FormRow'

const MotorControlOptions = ({ formData, handleInputChange }) => {
  return <>
        <FormRow label="Motor Position" colspan={1}>
            <select
                id="motorPosition"
                name="motorPosition"
                value={formData.motorPosition}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 md:text-base"
                required
            >
                <option value="">Select</option>
                <option value="Left">Left</option>
                <option value="Right">Right</option>
            </select>
        </FormRow>

        <FormRow label="Single/Multi Remote" colspan={1}>
        <select
            id="motorizedRemote"
            name="motorizedRemote"
            value={formData.motorizedRemote}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 md:text-base"
            required
        >
            <option value="Remote1">Remote 1</option>
            <option value="Remote2">Remote 2</option>
            <option value="Remote3">Remote 3</option>
            <option value="Remote4">Remote 4</option>
            <option value="Remote5">Remote 5</option>
            <option value="Remote6">Remote 6</option>
            <option value="Remote7">Remote 7</option>
            <option value="Remote8">Remote 8</option>
            <option value="Remote9">Remote 9</option>
            <option value="Remote10">Remote 10</option>
        </select>
        </FormRow>

        <FormRow label="Smart Hub" colspan={1}>
            <select
                id="smartHub"
                name="smartHub"
                value={formData.smartHub}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md p-2 md:text-base"
            >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </FormRow>
    </>
}

export default MotorControlOptions
