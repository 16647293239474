import React, { useState, useCallback, useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';

const ImageCarousel = ({ images, headerText, shadesId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, images.length]);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <div className='max-w-screen-xl mx-auto p-4'>
      <div id={shadesId} className='mb-4 text-center text-gray-700 font-semibold text-lg pt-12'>
        {headerText}
      </div>
      <div className='max-w-[600px] mx-auto relative group'>
        <div className='relative w-full h-[450px] rounded-2xl overflow-hidden'>
          <img
            src={images[currentIndex].imgSrc} 
            alt=''
            className='w-full h-full object-contain border-4 border-white transition-opacity duration-500'
          />
        </div>
      </div>
      {/* Left Arrow */}
      <div className='absolute top-1/2 -translate-y-1/2 left-5 transform -translate-y-1/2 text-2xl rounded-full p-2 group-hover:bg-black/60 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className='absolute top-1/2 -translate-y-1/2 right-5 transform -translate-y-1/2 text-2xl rounded-full p-2 group-hover:bg-black/60 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      {/* Dot indicators */}
      <div className='flex top-4 justify-center py-2'>
        {images.map((slide, slideIndex) => (
          <div
            className='text-2xl cursor-pointer'
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;