import React from 'react'
import CatalogSlider from '../../../components/CatalogSlider';

const Rollers = () => {
    const rollerImages = [
        {
          imgSrc: require('images/roller/rollercatalog-1.jpg'),
          headerText: 'Carnation Shades',
          pdfUrl: require('catalogs/Roller/roller-carnation-catalog.pdf') 
        },
        {
            imgSrc: require('images/roller/rollercatalog-2.jpg'),
            headerText: 'Fabric Shades',
            pdfUrl: require('catalogs/Roller/roller-fabric-catalog.pdf')
        },
        {
            imgSrc: require('images/roller/rollercatalog-3.jpg'),
            headerText: 'Lily Shades',
            pdfUrl: require('catalogs/Roller/roller-lily-catalog.pdf')
        },
        {
            imgSrc: require('images/roller/rollercatalog-4.jpg'),
            headerText: 'Olive Shades',
            pdfUrl: require('catalogs/Roller/roller-olive-catalog.pdf')
        },
        {
            imgSrc: require('images/roller/rollercatalog-5.jpg'),
            headerText: 'Orchids Shades',
            pdfUrl: require('catalogs/Roller/roller-orchids-catalog.pdf')
        },{
            imgSrc: require('images/roller/rollercatalog-6.jpg'),
            headerText: 'Peony Shades',
            pdfUrl: require('catalogs/Roller/roller-peony-catalog.pdf')
        },{
            imgSrc: require('images/roller/rollercatalog-7.jpg'),
            headerText: 'Sugar Maple Shades',
            pdfUrl: require('catalogs/Roller/roller-sugarmaple-catalog.pdf')
        },{
            imgSrc: require('images/roller/rollercatalog-8.jpg'),
            headerText: 'Tulip Shades',
            pdfUrl: require('catalogs/Roller/roller-tulip-catalog.pdf')
        },{
            imgSrc: require('images/roller/rollercatalog-9.jpg'),
            headerText: 'Water Lily Shades',
            pdfUrl: require('catalogs/Roller/roller-waterlily-catalog.pdf')
        },
    ];
    

    return <>
        <CatalogSlider images={rollerImages} headerText='Roller Shade Catalogs' shadesId='roller'  />;
    </>
}

export default Rollers