import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [userCart, setUserCart] = useState({ user: {}, cartItems: [] });

  useEffect(() => {
    // Retrieve cart items from localStorage on app load
    const storedUserCart = JSON.parse(localStorage.getItem('xpertBlindsUserCart'));
    
    if (storedUserCart?.cartItems?.length) {
      setUserCart(storedUserCart);
    }
  }, []);

  useEffect(() => {
    // Update local storage whenever cartItems change
    localStorage.setItem('xpertBlindsUserCart', JSON.stringify(userCart));
    
  }, [userCart]); // This useEffect watches for changes in userCart

  const addUserCart = (item, user) => {
    setUserCart((prevUserCart) => ({
      user,
      cartItems: [...prevUserCart.cartItems, item],
    }));
  };

  const editUserCart = (index, newItem, user) => {
    setUserCart((prevUserCart) => {
      const updatedCartItems = [...prevUserCart.cartItems];
      updatedCartItems[index] = newItem;
      return {
        user,
        cartItems: updatedCartItems,
      };
    });
  };

  const removeCartItem = (index) => {
    setUserCart((prevUserCart) => {
      const updatedCartItems = [...prevUserCart.cartItems];
      updatedCartItems.splice(index, 1);
      return {
        user: prevUserCart.user, // Keep the user data as it is
        cartItems: updatedCartItems,
      };
    });
  };

  const clearCart = () => {
    setUserCart((prevUserCart) => ({
      user: prevUserCart.user, // Keep the user data as it is
      cartItems: [], // Set cartItems to an empty array
    }));
  };

  return (
    <CartContext.Provider value={{ userCart, addUserCart, editUserCart, removeCartItem, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
