import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import BlindsMeasurementForm from './BlindsMeasurementForm';
import CartPreviewPageWrapper from './CartPreviewPageWrapper';

const QuoteRoutes = () => {
  return (
     <Routes>
        <Route path='/' element={<Outlet />}>
          <Route index element={<BlindsMeasurementForm />} />
          <Route path=":index" element={<BlindsMeasurementForm />} />
        </Route>
        <Route path="/cart-preview" element={ <CartPreviewPageWrapper/> } />
      </Routes>
  );
};

export default QuoteRoutes;
