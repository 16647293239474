import React from 'react'
import CatalogSlider from '../../../components/CatalogSlider';

const ZebraShades = () => {
    const rollerImages = [
        {
          imgSrc: require('images/zebra/zebrashades.jpg'),
          headerText: 'Zebra/Dual Shades',
          pdfUrl: require('catalogs/Zebra/zebra-catalog.pdf') 
        },
    ];
    

    return <>
        <CatalogSlider images={rollerImages} headerText="Zebra Shade Catalogs" shadesId='zebra' />;
    </>
}

export default ZebraShades