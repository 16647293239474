import axios from "axios";
import config from "config";

const apiUrl = config.apiUrl;
const baseURL = process.env.REACT_APP_API_URL || apiUrl;

const http = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-Type": "application/json",
    }
});

export default http;