import React, { useState } from 'react';
import blinds from '../images/contact.jpg';
import http from 'utils/http-common';
import { useNavigate } from 'react-router-dom'; 

const Contact = (props) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    message: '',
  });

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null); 

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) {
      newErrors.firstName = 'First name is required';
    }
    if (!formData.lastName) {
      newErrors.lastName = 'Last name is required';
    }
    if (!(formData.email || formData.phone)) {
      newErrors.contact = 'Email or phone is required';
    }
    if (formData.message.length > 500) {
      newErrors.message = 'Message should not exceed 500 characters.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Calculate remaining characters
  const remainingCharacters = 500 - formData.message.length;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Submit form or send API request here
      try {
        await http.post('/send-email/newCustomerInfo', formData);
  
        // Clear form fields
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: '',
          message: '',
        });

        // Set the submission status to success
        setSubmissionStatus('success');
      } catch (error) {
        console.error('Error submitting form data:', error);
        navigate('/error');
      }
      
    }
  };

  return (
    <div id='contact' className='max-w-[1140px] m-auto w-full p-4 py-16'>
      <h2 className='text-center text-gray-700'>Send us a message</h2>
      <p className='text-center text-gray-700 py-2'>We're standing by!</p>
      <div className='grid md:grid-cols-2'>
        <img
          src={blinds}
          alt='/'
          className='w-full md:h-full object-cover p-2 max-h-[500px] h-[200px]'
        />
        <form onSubmit={handleSubmit}>
          {errors.firstName && <p className='text-red-500 m-2'>{errors.firstName}</p>}
          {errors.lastName && <p className='text-red-500 m-2'>{errors.lastName}</p>}
          {errors.email && <p className='text-red-500 m-2'>{errors.email}</p>}
          {errors.phone && <p className='text-red-500 m-2'>{errors.phone}</p>}
          {errors.contact && <p className='text-red-500 m-2'>{errors.contact}</p>}
          {errors.address && <p className='text-red-500 m-2'>{errors.address}</p>}
          {errors.message && <p className='text-red-500 m-2'>{errors.message}</p>}
          {submissionStatus === 'success' && (
            <p className='text-green-500 m-2'>We've got your message! Our team will reach out to you at the earliest opportunity.</p>
          )}
          <div className='grid grid-cols-2'>
            <input
              className={`border m-2 p-2 ${errors.firstName ? 'border-red-500' : ''}`}
              type='text'
              placeholder='First'
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            />
            <input
              className={`border m-2 p-2 ${errors.lastName ? 'border-red-500' : ''}`}
              type='text'
              placeholder='Last'
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            />
            <input
              className={`border m-2 p-2 ${errors.email || errors.contact ? 'border-red-500' : ''}`}
              type='email'
              placeholder='Email'
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <input
              className={`border m-2 p-2 ${errors.phone || errors.contact ? 'border-red-500' : ''}`}
              type='tel'
              placeholder='Phone'
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
            <input
              className={`border col-span-2 p-2 m-2 ${errors.address ? 'border-red-500' : ''}`}
              type='text'
              placeholder='Address'
              value={formData.address}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
            />
            <textarea
              className={`border col-span-2 m-2 p-2 ${errors.message ? 'border-red-500' : ''}`}
              cols='30'
              rows='10'
              placeholder='Message'
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            ></textarea>
            <div>
              {remainingCharacters} characters remaining
            </div>
            
            <button className='col-span-2 m-2 submit-button' type='submit'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
