import React from 'react';
import TopBar from './components/TopBar';
import Navbar from './components/Navbar';
import UpdatedFooter from './components/UpdatedFooter';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import MeasuringTips from 'pages/help/MeasuringTips';
import ErrorPage from 'components/ErrorPage';
import ScrollToTop from 'components/ScrollToTop';
import QuoteRoutes from './pages/blinds-quote/QuoteRoutes';
import { CartProvider } from './pages/blinds-quote/CartContext';

function App() {
  return (
    <>
      <Router>
        <CartProvider>
          <ScrollToTop />
            <TopBar />
            <Navbar />
            <div className='min-h-screen'>
              <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/measuringtips' element={<MeasuringTips/>} />
                <Route path='/error' element={<ErrorPage/>} />
                <Route path='/blindsquote/*' element={<QuoteRoutes />} />
              </Routes>
            </div>
            <UpdatedFooter />
          </CartProvider>
      </Router>
    </>
  );
}

export default App;
