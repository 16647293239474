import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AiOutlineWhatsApp } from 'react-icons/ai';
import { MdEmail } from 'react-icons/md';
import xpertsLogo from '../images/logo/xperts-blinds-trans-512.png';
import { FaShoppingCart } from 'react-icons/fa';
import { useCart } from 'pages/blinds-quote/CartContext';

const TopBar = () => {
  const navigate = useNavigate();
  const { userCart } = useCart();

  return (
    <div className='flex flex-col md:flex-row justify-between items-center px-2 py-1'>
      <div className='flex items-center mb-2 md:mb-0'>
        <a href='/'>
          <img className='object-contain h-12 w-auto md:h-24 md:w-48' src={xpertsLogo} alt='' />
        </a>
      </div>
      <div className='flex items-center space-x-4 md:space-x-6'>
        <div className='hidden md:flex space-x-1 md:space-x-2'>
          <AiOutlineWhatsApp size={20} className='text-[var(--primary-dark)]' />
          <p className='text-xs md:text-sm text-gray-700 whitespace-nowrap'>972-656-9403</p>
        </div>
        <div className='hidden md:flex space-x-1 md:space-x-2'>
          <MdEmail size={20} className='text-[var(--primary-dark)]' />
          <p className='text-xs md:text-sm text-gray-700 whitespace-nowrap'>sales@xpertsblinds.com</p>
        </div>
        <button onClick={() => navigate("/blindsquote")} className='whitespace-nowrap'>Get a Free Quote</button>
      </div>
      <div className='md:hidden flex items-center space-x-1 md:space-x-2'>
        <AiOutlineWhatsApp size={20} className='text-[var(--primary-dark)]' />
        <p className='text-xs md:text-sm text-gray-700 whitespace-nowrap'>972-656-9403</p>
      </div>
      <div className='md:hidden flex items-center space-x-1 md:space-x-2'>
        <MdEmail size={20} className='text-[var(--primary-dark)]' />
        <p className='text-xs md:text-sm text-gray-700 whitespace-nowrap'>sales@xpertsblinds.com</p>
      </div>
      <div className="md:pr-10 cursor-pointer" onClick={() => {
            if (userCart.cartItems.length > 0) {
              navigate('/blindsquote/cart-preview');
            }
          }}>
            <FaShoppingCart className="inline text-2xl mr-2" />
            <span className="inline text-lg font-semibold">{userCart.cartItems.length}</span>
      </div>    
    </div>
  );
};

export default TopBar;
