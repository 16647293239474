import React from 'react'
import CatalogSlider from '../../../components/CatalogSlider';

const Curtains = () => {
    const rollerImages = [
        {
          imgSrc: require('images/curtains/curtainscatalog.jpg'),
          headerText: 'Curtains',
          pdfUrl: require('catalogs/Curtains/curtains-catalog.pdf') 
        },
    ];
    

    return <>
        <CatalogSlider images={rollerImages} headerText="Curtains Catalogs" shadesId='curtains' />;
    </>
}

export default Curtains