import React from 'react';
import xpertsBackground from '../images/hero-background.jpg';
import workswithalexa from '../images/smart/WorksWithAlexa-600x200.png'
import workswithga from '../images/smart/WorksWithGA_svg.png'

const Hero = () => {
  return (
    <div className='w-full h-[105vh] md:h-[80vh]'>
      <img src={xpertsBackground} alt='/' className='w-full h-full md:h-[90%] object-cover' />
      <div className='max-w-[1140px] mx-auto'>
        <div className='absolute top-[45%] md:top-[30%] w-full md:left-1/2 md:-translate-x-1/2 max-w-[1000px] h-full flex flex-col text-white p-4'>
          <h1 className='font-bold text-1xl md:text-4xl'>Elevating Your Space With Stylish Window Solutions</h1>
          <h2 className='text-sm md:text-2xl py-4 italic'>Transforming Windows, Elevating Spaces – Discover Your Perfect Blinds Today!</h2>
          <p className='text-xs md:text-base font-semibold'>
          Welcome to Xperts Blinds, where windows become art. Elevate your space with our curated collection of custom and smart blinds, each a masterpiece of design and function. Redefine your view and reimagine your surroundings with the perfect balance of privacy, style, and the convenience of smart technology. Explore a world where windows transform into canvases, waiting for your personal touch. Experience the allure of custom and smart blinds at Xperts Blinds, where intelligent living meets exceptional design.
          </p>
          <div className='flex pt-2'>
            <img src={workswithalexa} alt='Works with Alexa' className='w-15 h-8 md:w-30 md:h-16' />
            <img src={workswithga} alt='Works with Google Assistant' className='w-15 h-8 md:w-30 md:h-16' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
