import React, { useState, useEffect, useMemo }  from 'react';
import FormRow from '../../components/FormRow';
import ManualControlOptions from './ManualControlOptions';
import MotorControlOptions from './MotorControlOptions';
import CassetteOptions from 'pages/blinds-quote/CassetteOptions';
import { useCart } from './CartContext';
import { useParams, useNavigate } from 'react-router-dom';
import QuoteUserForm from 'components/QuoteUserForm';

const BlindsMeasurementForm = () => {
  const { userCart, addUserCart, editUserCart } = useCart();
  const { index } = useParams();
  const navigate = useNavigate();
  const buttonText = (index !== undefined ? "Update Window" : "Add Window")
  const [successMessage, setSuccessMessage] = useState('');

  const userInitialData = {
    firstName: '',
    middleName: '',
    lastName: '',
    phone: '',
    email: '',
    address: ''
  }

  const initialFormData = {
    roomName: '',
    fabricCode: '',
    mount: '',
    width: '',
    height: '',
    controlType: '',
    chainControlSide: '',
    chainControlStyle: '',
    chainLength: '',
    motorPosition: '',
    motorizedRemote: 'Remote1',
    smartHub: '',
    topOption: '',
    cassetteType: '',
    cassetteSize: '',
    fabricInsert: '',
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const [userData, setUserData] = useState( userCart.cartItems.length ? userCart.user : { ...userInitialData });
  const selectedItem = useMemo(() => userCart.cartItems[index], [index, userCart.cartItems]);
  
  // Define the resetFields configuration based on field changes
  const resetFields = {
    controlType: ['chainControlSide', 'chainControlStyle', 'chainLength','motorPosition','numberOfRemotes','motorizedRemote'],
    topOption: ['cassetteType', 'cassetteSize', 'fabricInsert'],
    // Add more fields and their corresponding resetFields as needed
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSuccessMessage('');
    
    if (resetFields[name]) {
      // Reset the specified fields to their default values
      const updatedFormData = { ...formData };
      for (const field of resetFields[name]) {
        updatedFormData[field] = initialFormData[field]; // Reset the field to its default value (empty string in this case)
      }
      setFormData({ ...updatedFormData, [name]: value }); // Update controlType value
    } else {
      // For other input fields, simply update the formData state
      setFormData({ ...formData, [name]: value });
    }

  };

  const handleUserInputChange = (e) => {
    const { name, value } = e.target;

    setUserData({ ...userData, [name]: value });
  }

  useEffect(() => {
    if (index !== undefined && selectedItem) {
      setFormData(selectedItem);
    }
  }, [index, selectedItem]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (index !== undefined) {
      // If in edit mode, update the cart item
      await editUserCart(index, formData, userData);
      setSuccessMessage('Updated Blind successfully.'); 
    } else {
      // Otherwise, add a new item to the cart
      await addUserCart(formData, userData);
      setSuccessMessage('Added Blind successfully.'); 
    }
    
    // Reset the form by setting form fields to initial values
    setFormData({ ...initialFormData });

    // Navigate to the desired route
    navigate('/blindsquote');
  };

  return <>
    <div className='bg-[var(--primary-dark)] py-4 md:py-4 mt-10'>
        <div className='container mx-auto text-white'>
            <h1 className='text-base text-center md:text-2xl font-bold'>Enter Your Blinds Measurements</h1>
        </div>
    </div>
    {successMessage && (
      <div className="text-center text-green-500 p-2 rounded-md font-bold mt-4">
        {successMessage}
      </div>
    )}
    <form onSubmit={handleSubmit}>
      <div className="container mx-auto max-w-8xl mt-5">
        <QuoteUserForm userData={userData} handleUserInputChange={handleUserInputChange}/>

        <div className="h-px mb-4 bg-gray-500"></div> 
            
        <FormRow label="Room Name" colspan={1}>
          <input
            type="text"
            id="roomName"
            name="roomName"
            value={formData.roomName}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2"
            maxLength="80"
            required
          />
        </FormRow>

        <FormRow label="Fabric Code" colspan={1}>
          <input
            type="text"
            id="fabricCode"
            name="fabricCode"
            value={formData.fabricCode}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2"
            maxLength="80"
            required
          />
        </FormRow>

        <FormRow label="Mount" colspan={1}>
          <select
            id="mount"
            name="mount"
            value={formData.mount}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 md:text-base"
            required
          >
            <option value="">Select</option>
            <option value="Inside">Inside</option>
            <option value="Outside">Outside</option>
          </select>
        </FormRow>

        <FormRow label="Width" colspan={1}>
          <input
              type="text"
              id="width"
              name="width"
              value={formData.width}
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md p-2 md:w-24"
              maxLength="3"
              required
          /> inches
        </FormRow>
        <FormRow label="Height" colspan={1}>
          <input
            type="text"
            id="height"
            name="height"
            value={formData.height}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 md:w-24"
            maxLength="3"
            required
          /> inches
        </FormRow>

        <div className="h-px mb-4 bg-gray-300"></div> 

        <FormRow label="Control Type" colspan={1}>
          <select
            id="controlType"
            name="controlType"
            value={formData.controlType}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 md:text-base"
            required
          >
            <option value="">Select</option>
            <option value="Cordless">Cordless</option>
            <option value="Manual">Manual</option>
            <option value="Motorized">Motorized</option>
          </select>
        </FormRow>

        {formData.controlType === 'Manual' && (
            <ManualControlOptions formData={formData} handleInputChange={handleInputChange} />
          )
        }

        {formData.controlType === 'Motorized' && (
            <MotorControlOptions formData={formData} handleInputChange={handleInputChange} />
          )
        }

        <div className="h-px mb-4 bg-gray-300"></div> 

        <FormRow label="Top Position" colspan={1}>
          <select
              id="topOption"
              name="topOption"
              value={formData.topOption}
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md p-2 md:text-base"
              required
          >
              <option value="">Select</option>
              <option value="Cassette">Cassette</option>
              <option value="OpenRoll">Open Roll</option>
          </select>
        </FormRow>

        {formData.topOption === 'Cassette' && (
            <CassetteOptions formData={formData} handleInputChange={handleInputChange} />
          )
        }
        <div className="flex justify-center">
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md">
            {buttonText}
          </button>
        </div>
      </div>
    </form> 
  </>
}

export default BlindsMeasurementForm;
